<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="100px">
      <el-form-item label="充电站" prop="station_id" :rules="rules">
        <SelectStation v-model="formData.station_id"></SelectStation>
      </el-form-item>
      <!-- <el-form-item label="停车位编号">
        <el-input style="width: 400px" v-model="formData.number" size="small" placeholder="请输入" clearable></el-input>
      </el-form-item> -->
      <el-form-item label="地锁名称" prop="name" :rules="rules">
        <el-input style="width: 400px" v-model="formData.name" size="small" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="控制器号" prop="equipment_id_hex" :rules="rules">
        <el-input
          :disabled="$route.params.id"
          style="width: 400px"
          v-model="formData.equipment_id_hex"
          size="small"
          placeholder="请输入"
          clearable
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="状态">
        <el-select style="width: 400px" size="small" v-model="formData.status" placeholder="请选择">
          <el-option v-for="item in dict_charging_lock_status" :key="item.value" :label="item.name" :value="item.value"> </el-option>
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="地锁描述">
        <el-input style="width: 400px" type="textarea" v-model="formData.description" size="small" placeholder="请输入" clearable></el-input>
      </el-form-item> -->

      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import SelectStation from '@/views/components/select-station.vue'
import { dict_charging_lock_status } from '@/enum/dict.js'
import { addAPI, editAPI, getDetailAPI } from './api'
import { getStationListAPI } from '../api'
export default {
  name: 'AddOrEdit',
  components: { SelectStation },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        station_id: '',

        description: '',
        status: '',

        equipment_id_hex: ''
      },
      dict_charging_lock_status,
      stationList: []
    }
  },

  mounted() {
    this.getDictList()
    if (this.$route.params.id) this.getDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail() {
      this.formData = await getDetailAPI(this.$route.params.id)
    },
    async getDictList() {
      const res = await getStationListAPI()
      this.stationList = res.data || []
    }
  }
}
</script>

<style lang="scss" scoped></style>
