import { http } from '@/http/axios.js'
// 充电站表
export function getStationListAPI(params) {
  return http({
    url: '/company/chargingStation/list',
    method: 'get',
    params
  })
}
// 充电桩列表
export function getPileListAPI(params) {
  return http({
    url: '/company/charging/chargingPile/list',
    method: 'get',
    params
  })
}
// 充电枪列表
export function getListAPI(params) {
  return http({
    url: '/company/chargingGun/list',
    method: 'get',
    params
  })
}
// 地锁列表
export function getLockListAPI(params) {
  return http({
    url: '/company/chargingLock/list',
    method: 'get',
    params
  })
}
// 停车位列表
export function getSpaceListAPI(params) {
  return http({
    url: '/company/parkingSpace/list',
    method: 'get',
    params
  })
}
